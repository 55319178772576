import { AxiosError } from "axios"

const getErrorsFromErrorObject = error => {
    let errorMessages = []

    for (const key of Object.keys(error)) {
        switch (key) {
            case "message":
                errorMessages.push(error[key])
                break
            case "detail":
                errorMessages.push(error[key])
                break
            case "success":
                break
            default:
                let errorMsg

                if (Array.isArray(error[key])) {
                    errorMsg = []

                    error[key].forEach(el => {
                        if (typeof el === "string") {
                            errorMsg.push(el)
                        } else if (typeof el === "object") {
                            for (const elKey of Object.keys(el)) {
                                errorMsg.push(`${el[elKey]}`)
                            }
                        }
                    })

                    errorMsg = errorMsg.toString()
                    errorMessages.push(`${errorMsg}`)
                } else if (typeof error[key] === "string") {
                    errorMsg = error[key]
                    errorMessages.push(`${errorMsg} `)
                } else if (typeof error[key] === "object") {
                    const errors = getErrorsFromErrorObject(error[key])
                    errorMessages = [...errorMessages, ...errors]
                }
        }
    }
    return errorMessages
}

export const getErrorMessages = (error: AxiosError | any): string[] => {
    let errorMessages: string[] = []

    if (error?.response?.data) {
        if (typeof error.response.data === "object") {
            const errors = getErrorsFromErrorObject(error?.response?.data)
            errorMessages = [...errorMessages, ...errors]
        }
    }

    if (error?.message && !error?.response?.data) {
        errorMessages.push(error?.message)
    }

    if (typeof error === "string") {
        errorMessages.push(error)
    }

    return errorMessages
}
